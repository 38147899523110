import React, { lazy, Suspense } from 'react';
import logo from './assets/logo.png'; // Adjust the path according to your folder structure

const Banner = lazy(() => import('./components/Banner'));
const Header = lazy(() => import('./components/Header'));
const Nav = lazy(() => import('./components/Nav'));
const About = lazy(() => import('./components/About'));
const Services = lazy(() => import('./components/Services'));
const Work = lazy(() => import('./components/Work'));
const Contact = lazy(() => import('./components/Contact'));

const App = () => {
  return (
    <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
      <Suspense fallback={<div><img src={logo} alt="Loading..." /></div>}>
        <Header />
        <Banner />
        <Nav />
        <About />
        <Services />
        <Work />
        <Contact />
      </Suspense>
    </div>
  );
};

export default App;
